import React from 'react';

const Login = () => {
    return (
        <div>
            <h1>Home Page</h1>
            {/* Add your home page content here */}
        </div>
    );
};

export default Login;
